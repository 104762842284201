import React, {useState} from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useBackgroundColor } from './BackgroundColorContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function CV() {
    const [pageNum, setPageNum] = useState(1)

    const nextPage = () => {
        if (pageNum < 3) {
            setPageNum(pageNum + 1);
        }
        else{
            setPageNum(1);
        }
    }
    
    const previousPage = () => {
        setPageNum(pageNum - 1);
    }
    if (pageNum >= 3){
        setPageNum(1);
    }

    const { isBackgroundBlack } = useBackgroundColor();

    return (
        <div id="CV" className={`${isBackgroundBlack ? "bg-zinc-900 text-gray-100" : "bg-white text-black"} pt-20 h-auto`}>
            <p className="text-gray-200 w-2/3 m-auto text-2xl text-center lg:hidden">Si rien n'apparait,cela veux dire que votre écran n'est pas assez grand pour afficher le PDF, cliquer sur le lien suivant en bleu pour le télécharger</p>
            <a href="/CV.pdf" download className="block pt-10 text-blue-400 hover:text-gray-600 text-2xl text-center w-2/3 m-auto lg:hidden">CV.pdf</a>
            <div className="hidden lg:block rounded-3xl bg-white w-2/3 m-auto">
                <Document file="/CV.pdf" renderMode='canvas' className="w-5/6 pl-40">
                    <Page pageNumber={pageNum}/>
                </Document>
            </div>
            <div className="hidden lg:flex w-1/3 m-auto py-8 flex-row justify-between text-2xl">
                <button onClick={previousPage} className="text-gray-200 px-3 py-2 border-4 rounded-3xl border-solid border-gray-200 bg-blue-400">Page précédente</button>
                <button onClick={nextPage} className="text-gray-200 px-3 py-2 border-4 rounded-3xl border-solid border-gray-200 bg-blue-400">Prochaine Page</button>
            </div>
        </div>
        );
    }
    
    export default CV;