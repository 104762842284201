import { Transition, Switch } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import React, { Fragment, useState, useEffect } from 'react';
import { useBackgroundColor } from '../BackgroundColorContext';
import AuthMenu from './authMenu';

function Header() {
    const location = useLocation();

  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const { isBackgroundBlack, setIsBackgroundBlack } = useBackgroundColor();

  useEffect(() => {
    setIsHeaderVisible(true);
  }, []);

  const showNightModeSwitch = location.pathname !== '/moneyCounter';

  const shouldRenderMenu = location.pathname !== '/register';

  return (
    <div>
      <Transition.Root show={isHeaderVisible}>
        <HeaderContent
          isBackgroundBlack={isBackgroundBlack}
          setIsBackgroundBlack={setIsBackgroundBlack}
          showNightModeSwitch={showNightModeSwitch}
          shouldRenderMenu={shouldRenderMenu}
        />
      </Transition.Root>
    </div>
  );
}

const HeaderContent = ({ isBackgroundBlack, setIsBackgroundBlack, showNightModeSwitch, shouldRenderMenu }) => (
  <Transition.Child
    as={Fragment}
    enter="transform ease-in-out duration-500"
    enterFrom="-translate-y-full"
    enterTo="translate-y-0"
  >
    <header className="bg-blue-400 text-center flex flex-row justify-center font-mono relative">
      <FadeIn delay="delay-[300ms]">
        <Link to="/" className="absolute left-3 bottom-0 mb-2 text-blue-700 hover:text-gray-600 text-1xl sm-text-2xl">
          Retourner au menu
        </Link>
      </FadeIn>
      <div className="w-full sm:w-2/3 xl:w-3/5">
        <FadeInTop delay="delay-[300ms]">
          <h1 className="text-3xl 2xl:text-6xl text-gray-200 mt-14 sm:mt-1 sm:mb-8">Curriculum Vitae<br />Alexandre Sammut</h1>
        </FadeInTop>
        <FadeInRight delay="delay-[500ms]">
          <p className="text-right text-gray-300 mb-10 pr-6 sm:pr-0 sm:mb-0 sm:text-2xl">Amusez-vous à explorer mon CV</p>
        </FadeInRight>
      </div>
      {showNightModeSwitch && (
        <Switch.Group>
          <div className="absolute right-4 top-4 flex items-center">
            <Switch.Label className={`${isBackgroundBlack ? "text-black" : "text-white" } font-mono mr-2 sm:mr-4 text-sm sm:text-2xl`}>Night Mode</Switch.Label>
            <Switch
              checked={isBackgroundBlack}
              onChange={setIsBackgroundBlack}          
            >
              <span className={`${isBackgroundBlack ? "bg-black" : "bg-white" } rounded shadow p-2 h-7 w-10 sm:h-14 sm:w-24 flex`}>
                <span
                  className={`${
                    isBackgroundBlack ? 'bg-white translate-x-full rounded-full' : 'bg-black'
                  } block h-full w-1/2 rounded transition duration-300 ease-in-out transform`}
                />
              </span>
            </Switch>
          </div>
        </Switch.Group>
      )}
      {shouldRenderMenu && <AuthMenu />}
    </header>
  </Transition.Child>
);

const FadeInTop = ({ delay, children }) => (
    <Transition.Child
        enter={`transition-all ease-in duration-700 ${delay}`}
        enterFrom="opacity-0 translate-y-6"
        enterTo="opacity-100 translate-y-0"
    >
        {children}
    </Transition.Child>
);

const FadeInRight = ({ delay, children }) => (
    <Transition.Child
        enter={`transition-all ease-in duration-700 ${delay}`}
        enterFrom="opacity-0 -translate-x-52"
        enterTo="opacity-100 translate-x-0"
    >
        {children}
    </Transition.Child>
);
const FadeIn = ({ delay, children }) => (
    <Transition.Child
        enter={`transition-all ease-in duration-700 ${delay}`}
        enterFrom="opacity-0"
        enterTo="opacity-100"
    >
        {children}
    </Transition.Child>
);

export default Header;
